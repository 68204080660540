import Vue from 'vue'
import './plugins/config'
import './plugins/rem'
import './plugins/element'
import './plugins/common'
import router from './router'
import store from './store'
import i18n from './langs'
import App from './App.vue'
import './plugins/axios'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/theme.css';
import './assets/css/common.less'
import './assets/css/ele.less'
import '@/shared/style/global.js';
import "./icons";
import { flexLabelWidth } from './plugins/common'
import echarts from "./plugins/echarts";
import infiniteScroll from "vue-infinite-scroll";
import Rsa from "./plugins/rsa.js"
Vue.prototype.Rsa = Rsa


Vue.use(infiniteScroll);
Vue.config.productionTip = false
Vue.prototype.flexLabelWidth = flexLabelWidth


router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    if(sessionStorage.getItem('token')){ //判断本地是否存在access_token
      next();
    }else {
     if(to.path === '/login'){
        next();
      }else {
        next({
          path:'/login'
        })
      }
    }
  }
  else {
    next();
  }
  /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
  if(to.fullPath == "/login"){
    if(sessionStorage.getItem('token')){
      next({
        path:from.fullPath
      });
    }else {
      next();
    }
  }
});


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
