export function isEmpty(obj){
    
    return (typeof obj === 'undefined' || obj === null || obj === "" || obj.length == 0);
}

export function dateFormat(fmt, date) {
    // console.log(date)
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}
export function debounce(fn, wait) {
    
        if (window.timeout) clearTimeout(window.timeout)
        window.timeout = setTimeout(() => {
          fn()
        }, wait)
   
}
export function concatParams(params) {
    let paramsStr = ''
    for (var k in params) {
        paramsStr += k + '=' + params[k] + '&'
    }
    paramsStr = '?' + paramsStr.substr(0, paramsStr.length - 1)
    return paramsStr
}
export function concatTableData(data,total,currentPage,pageSize) {
    data.forEach((item,index) => {
        // console.log(item)
        if(total >= 100){
            item.sortNum =(((currentPage - 1)*pageSize) + (index + 1)).toString().padStart(3,0)
    
        }else{
            item.sortNum = (((currentPage - 1)*pageSize) + (index + 1)).toString().padStart(2, 0)
        }
    });
    

    return data
}

//根据表格表头的宽度设置宽度
export function flexLabelWidth(str) {
    let flexWidth = 0
    // console.log(str)
    for (const char of str) {
        if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
            // 如果是英文字符，为字符分配8个单位宽度
            flexWidth += 11
        } else if (char >= '\u4e00' && char <= '\u9fa5') {
            // 如果是中文字符，为字符分配15个单位宽度
            flexWidth += 31
        } else {
            // 其他种类字符，为字符分配8个单位宽度
            flexWidth += 12
        }
    }
    if (flexWidth < 80) {
        // 设置最小宽度
        flexWidth = 80
    }
    // if (flexWidth > 250) {
    //   // 设置最大宽度
    //   flexWidth = 250
    // }
    return flexWidth + 'px'
}

export function dateGmt8(date) {
    if(date){
        var str = date.replace(/-/g, "/")
        var timestap = Date.parse(new Date(str));
        var newDate = new Date(timestap + 8 * 3600 * 1000) 
        // console.log(timestap)

        var fmt = "yyyy-MM-dd hh:mm:ss"
        
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(
            RegExp.$1,
            (newDate.getFullYear() + '').substr(4 - RegExp.$1.length)
            )
        }
        let o = {
            'M+': newDate.getMonth() + 1,
            'd+': newDate.getDate(),
            'h+': newDate.getHours(),
            'm+': newDate.getMinutes(),
            's+': newDate.getSeconds()
        }
        for (let k in o) {
            if (new RegExp('(' + k + ')').test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1
                ? o[k]
                : ('00' + o[k]).substr(('' + o[k]).length)
            )
        }
        return fmt
    }else{
        return ''
    }
    // console.log(date)
    

}


