
//index.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store'
Vue.use(VueI18n);
import locale from 'element-ui/lib/locale';
import elEn from "element-ui/lib/locale/lang/en";
import elCn from "element-ui/lib/locale/lang/zh-CN";


function loadLocaleMessages() {
	//检测locales文件夹中有多少个语系
	const locales = require.context(
	  "../langs",
	  true,
	  /.json$/
	);
	// console.log(locales)
	const messages = {};
	locales.keys().forEach(file => {
	  const keyArr = file.split('/');
	  keyArr.shift();
	  messages[keyArr.join('.').replace(/\.json$/g, '')] = locales(file);
	});
	// console.log(messages)
	return {
	  zh: { ...messages.zh, ...elCn },
	  en: { ...messages.en, ...elEn },
	};
  }
  const i18n = new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || localStorage.getItem('localeLanguage') || _DEFAULT_LANGUAGE_,
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || localStorage.getItem('localeLanguage') || _DEFAULT_LANGUAGE_,
	messages: loadLocaleMessages()
  });
  locale.i18n((key, value) => i18n.t(key, value));


// 暴露i18n
export default i18n;