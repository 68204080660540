import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  state: {
    lang: localStorage.localeLanguage ? localStorage.localeLanguage : _DEFAULT_LANGUAGE_,
    token: sessionStorage.token ? sessionStorage.token : '',
    userInfo: {},
    checkPortfolio: {},
    activeTab:"signalBaseInfo",
    mobileFlag:false,
    refillMainAccount:0,
    refillSubAccount:0,
    refillType:1,
    chooseSignal:'',
    refillData:{}
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      sessionStorage.setItem('token', state.token)
    },
    setLang(state, lang) {
      state.lang = lang
      localStorage.setItem('localeLanguage', state.lang)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setPortfolio(state, portfolio) {
      state.checkPortfolio = portfolio
    },
    setActiveTab(state, activeTab) {
      state.activeTab = activeTab
    },
    setMobileFlag(state, mobileFlag) {
      state.mobileFlag = mobileFlag
    },
    setRefillMainAccount(state, refillMainAccount) {
      state.refillMainAccount = refillMainAccount
    },
    setRefillSubAccount(state, refillSubAccount) {
      state.refillSubAccount = refillSubAccount
    },
    setRefillType(state, refillType) {
      state.refillType = refillType
    },
    setChooseSignal(state, chooseSignal) {
      state.chooseSignal = chooseSignal
    },
    setRefillData(state, refillData) {
      state.refillData = refillData
    },
  },
  actions: {
  },
  modules: {
  }
})
