<template>
    <svg class="svg-icon" aria-hidden="true" :style="style">
      <use :xlink:href="iconName" />
    </svg>
  </template>

  <script>
  export default {
    props: {
      iconClass: {
        type: String,
        required: true,
      },
      color: {
            type: String,
            default: "#999999"
        }
    },
    computed: {
      iconName() {
        return `#icon-${this.iconClass}`;
      },
      style() {
            return { "--color": this.color };
        }
    },
  };
</script>
  <style lang="less">
    .svg-icon {
        width: 1em;
        height: 1em;
        fill: currentColor;
        overflow: hidden;
        color: #000;
    }
  </style>