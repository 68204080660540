<template>
  <div id="app" :class="[lang == 'zh' ? 'zhHtml' : 'enHtml', mobileFlag ? 'mobileHtml' : 'pcHtml']">
    <router-view />
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ecwap",
  data() {
    return {
      sign: "",
    };
  },
  computed: {
    ...mapState(["lang", "mobileFlag"]),
  },
  watch: {
        $route(to,from){
          if(from.name == null){
                sessionStorage.removeItem('beforeunload');
          }
      }
  },
  mounted() {
    if (this.isMobile()) {
      this.$store.commit("setMobileFlag", true);
    } else {
      this.$store.commit("setMobileFlag", false);
    }
    // window.addEventListener('beforeunload', e => {
    //   sessionStorage.setItem('beforeunload', 1);
    // });
    // // 监听页面刷新
    // if (sessionStorage.getItem('beforeunload') == 1) {
    //   let name = sessionStorage.getItem("routerName");
    //   if (name) {
    //     this.$nextTick(function () {
    //       this.$router.push({ path: name });
    //     });
    //   }
    // }


  },
  // 测试
  // created() {
  //   var url = window.location.href; //获取当前url
  //   var cs = url.split("?")[1]; //获取?之后的参数字符串
  //   var cs_arr = cs.split("&"); //参数字符串分割为数组
  //   var cs = {};
  //   for (var i = 0; i < cs_arr.length; i++) {
  //     //遍历数组，拿到json对象
  //     cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
  //   }
  //   this.sign = cs.sign;
  //   this.getToken();
  // },

  // 测试2
  // created(){
  //   let url = window.location.href;
  //   let getTokenUrl = "/api/login/login"
  //   let params = url.split('?')[1];
  //   if(params!=undefined && params!=''){
  //     getTokenUrl = "/api/login/LoginBySignature?"+params
  //   }
  //   this.getToken(getTokenUrl);
  //   setup('zh');
  // },

 // 正式对接
  created() {
    // this.isMobile()
    // var url = window.location.href; //获取当前url
    // if(url){
    //   console.log(url)
    //   var cs = url.split("?")[1]; //获取?之后的参数字符串
    //   var cs_arr = cs.split("&"); //参数字符串分割为数组
    //   var cs = {};
    //   for (var i = 0; i < cs_arr.length; i++) {
    //     //遍历数组，拿到json对象
    //     cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
    //   }
    //   this.sign = cs.sign;
    //   if(this.sign){
    //     this.getPage()
    //   }
    // }
  },
  
  methods: {
    // getPage(){
    //   // console.log(this.sign)
    //   this.$axios.get("/Api/Login/CopyWealth?email=" + this.sign).then((res) => {
    //       let dt = res.data;
    //       // console.log(dt)
    //       if (dt.isSuccess) {

    //         this.$router.push({ path: "/ForgetPassword" });
    //         // if(sessionStorage.getItem('routerName') == '/MyFollow'){
    //         //   this.$router.push({ path: "/MyFollow" });
    //         // }
    //       }else{
    //         this.$router.push({ path: "/Login" });

    //       }

          
    //     });
    // },
    isMobile() {
      // let flag = navigator.userAgent.match(
      //   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      // );
      var os = function () {
        var ua = navigator.userAgent,
          isWindowsPhone = /(?:Windows Phone)/.test(ua),
          isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
          isAndroid = /(?:Android)/.test(ua),
          isFireFox = /(?:Firefox)/.test(ua),
          isChrome = /(?:Chrome|CriOS)/.test(ua),
          isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
          isPhone = /(?:iPhone)/.test(ua) && !isTablet,
          isPc = !isPhone && !isAndroid && !isSymbian;
        return {
          isTablet: isTablet,
          isPhone: isPhone,
          isAndroid: isAndroid,
          isPc: isPc
        };
      }();
      if (os.isAndroid || os.isPhone) {
        return true
      } else if (os.isTablet) {
        return false
      } else if (os.isPc) {
        return false
      }
    },
  },
};
</script>


<style lang="less">
#app {
  height: 100%;
}
</style>
