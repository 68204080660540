
 BASE_URL = process.env.NODE_ENV == 'production' ? 'https://api.copy-wealth.com' : 'http://192.168.0.129:4002' 


 _DEFAULT_LANGUAGE_ = 'zh'


 
 
 
