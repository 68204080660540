import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
};


const routes = [
  {
    path: '/',
    redirect: '/Login',
  },
  {
    path: '/403',
    name: '403',
    component: () => import('../views/pages/403.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/pages/Login.vue')
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/pages/Register.vue')
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: () => import('../views/pages/ForgetPassword.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    meta: { requireAuth: true },
    component: () => import('@/views/public/Home.vue'),
    children: [
      {
        path: '/HomeIndex',
        name: 'HomeIndex',
        meta: { requireAuth: true },
        nameTxt:'menu_home',
        component: () => import('@/views/pages/HomeIndex.vue'),
      },
      {
        path: '/MyFollow',
        name: 'MyFollow',
        meta: { requireAuth: true },
        nameTxt:'MyFollowers_Myfollowers_list',
        component: () => import('@/views/pages/MyFollow.vue'),
      },
      {
        path: '/FollowInvited',
        name: 'FollowInvited',
        meta: { requireAuth: true },
        nameTxt:'MyFollowers_BeingInvited',
        component: () => import('@/views/pages/FollowInvited.vue'),
      },
      {
        path: '/FollowHistory',
        name: 'FollowHistory',
        meta: { requireAuth: true },
        nameTxt:'MyFollowers_Myfollowers_history',
        component: () => import('@/views/pages/FollowHistory.vue'),
      },
      {
        path: '/MyRecord',
        name: 'MyRecord',
        meta: { requireAuth: true },
        nameTxt:'MyFollowers_OperationRecord',
        component: () => import('@/views/pages/MyRecord.vue'),
      },
      {
        path: '/MySignal',
        name: 'MySignal',
        meta: { requireAuth: true },
        nameTxt:'MyFollowers_MySignalSource',
        component: () => import('@/views/pages/MySignal.vue'),
      },
      {
        path: '/MyAccount',
        name: 'MyAccount',
        meta: { requireAuth: true },
        nameTxt:'',
        component: () => import('@/views/pages/MyAccount.vue'),
        children: [
          {
            path: '/FollowAccount',
            name: 'FollowAccount',
            meta: { requireAuth: true },
            component: () => import('@/views/pages/FollowAccount.vue'),
          },
          {
            path: '/ManualRefill',
            name: 'ManualRefill',
            meta: { requireAuth: true },
            component: () => import('@/views/pages/ManualRefill.vue'),
          },
          {
            path: '/signalHistoricalProfit',
            name: 'historicalProfit',
            meta: { requireAuth: true },
            component: () => import('@/views/pages/SignalDetail/SignalHistoricalProfit'),
          },
        ]
      },
      {
        path: '/RefillDetail',
        name: 'RefillDetail',
        meta: { requireAuth: true },
        nameTxt:'lostOrderDetailTitle',
        component: () => import('@/views/pages/RefillDetail.vue'),
      },
      {
        path: '/Disclaimers',
        name: 'Disclaimers',
        meta: { requireAuth: true },
        nameTxt:'DisclaimersTitle',
        component: () => import('@/views/pages/Disclaimers.vue'),
      },
      {
        path: '/FollowSymbol',
        name: 'FollowSymbol',
        meta: { requireAuth: true },
        nameTxt:'followSymbol_title',
        component: () => import('@/views/pages/FollowSymbol.vue'),
      },
      {
        path: '/historicalProfit',
        name: 'historicalProfit',
        meta: { requireAuth: true },
        nameTxt:'historicalProfit',
        component: () => import('@/views/pages/HistoricalProfit'),
      },
      {
        path: '/FollowEcharts',
        name: 'FollowEcharts',
        meta: { requireAuth: true },
        nameTxt:'',
        component: () => import('@/views/pages/FollowEcharts.vue'),
      },
      {
        path: '/MyMessage',
        name: 'MyMessage',
        meta: { requireAuth: true },
        nameTxt:'menu_message',
        component: () => import('@/views/pages/MyMessage.vue'),
      },
      {
        path: '/HelpCenter',
        name: 'HelpCenter',
        meta: { requireAuth: true },
        nameTxt:'title_helpTxt',
        component: () => import('@/views/pages/HelpCenter.vue'),
      },
      {
        path: '/MyPerson',
        name: 'MyPerson',
        meta: { requireAuth: true },
        nameTxt:'person_title',
        component: () => import('@/views/pages/MyPerson.vue'),
      },
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {
  if (from.name != null)
    sessionStorage.setItem("routerName", to.fullPath);
  next();
});

export default router
