"use strict";

import Vue from 'vue'
import axios from "axios"
import router from '@/router'
import store from '@/store'
import { Notification } from 'element-ui'
import qs from 'qs'
import i18n from '../langs/index'

// 是否正在刷新的标记
let isRefreshing = false

let config = {
	baseURL: process.env.VUE_APP_URL, // 配置axios请求服务器地址
	headers: {
		'Content-Type': 'application/json'
	},
	timeout: 60 * 1000
};
console.log(i18n.t('oneAccountTips'))
const _axios = axios.create(config);

_axios.interceptors.request.use(
	config => {
		config.headers['Accept-Language'] = store.state.lang == 'zh'?'zh-CN':'en-US'
		config.headers['Cache-Control'] = 'no-cache'
		if (store.state.token) {
			config.headers.Authorization = 'Bearer ' + store.state.token
			// config.headers['ibcode'] = store.state.defaultIbCode
		}
		if(config.method=='post'){
			// config.headers['Content-Type'] = "application/x-www-form-urlencoded;charset=utf-8";
			config.data = qs.parse(config.data)
		}	
		return config;
	},
	error => {

		Notification.error({title: i18n.t('MyFollowers_prompt'), message:i18n.t('errorCommon')})
		
		return Promise.reject(error);
	}
);

_axios.interceptors.response.use(
	response => {

		return response;
	},
	error => {
		// console.log(error)
		if(error.response.status == 401){
			store.commit("setToken", '');
			if( !isRefreshing ){
				//修改状态，进入更新token阶段
				isRefreshing = true
				Notification.error({title: i18n.t('MyFollowers_prompt'), message:i18n.t('oneAccountTips')})
				localStorage.clear()
				sessionStorage.clear()
				router.push({ path: '/Login'});
			}
			
		}else{
			Notification.error({title: i18n.t('MyFollowers_prompt'), message:i18n.t('errorCommon')})

		}
		
		return Promise.reject(error);
	}
);

Plugin.install = function(Vue, options) {
	Vue.axios = _axios;
	window.axios = _axios;
	Object.defineProperties(Vue.prototype, {
		axios: {
			get() {
				return _axios;
			}
		},
		$axios: {
			get() {
				return _axios;
			}
		},
	});
};

Vue.use(Plugin)

export default Plugin;