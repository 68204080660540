import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import Vue from 'vue';
import {
  Button,
  Checkbox,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Loading,
  Message,
  Drawer,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Tabs,
  TabPane,
  MessageBox,
  DatePicker,
  CheckboxGroup,
  Dialog,
  Upload,
  Notification,
  Collapse,
  CollapseItem,
  Table,
  TableColumn,
  Pagination,
  Switch,
  Radio,
  RadioGroup,
  RadioButton,
  Result,
  InputNumber,
  Popover,
  Breadcrumb,
  BreadcrumbItem,
  Tooltip,
  Card,
  Progress,
} from 'element-ui';

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Option)
// Vue.use(Loading.directive);
Vue.use(Drawer);
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(CheckboxGroup)
Vue.use(Upload)
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Switch);
Vue.use(Radio); 
Vue.use(RadioGroup); 
Vue.use(RadioButton);
Vue.use(Result);
Vue.use(Popover);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Tooltip);
Vue.use(Card);
Vue.use(Progress);








Vue.component(CollapseTransition.name, CollapseTransition)


Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$messagebox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
// Vue.prototype.$message = Message;

Vue.prototype.$message = function (msg) {
  return Message({
    message: msg,
    duration: 1500,
    customClass: 'zZindex'
  })
}
// 分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1500,
    customClass: 'zZindex'
  })
}
Vue.prototype.$message.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 1500,
    customClass: 'zZindex'
  })
}
Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 1500,
    customClass: 'zZindex'
  })
}
Vue.prototype.$message.signalIRTips = function (msg) {
  return Message.success({
    message: msg,
    duration: 0,
    customClass: 'zZindex signalIRTips',
    showClose: true,
    iconClass:'el-icon-loading'
  })
}



